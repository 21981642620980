<template>
  <div class="modal-confirm">
    <div class="modal-confirm__body">
      <div class="modal-confirm__title">{{ title }}</div>
      <div class="modal-confirm__text" v-if="text">{{ text }}</div>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    title: {
      type: String,
      default: 'Ошибка'
    },
    text: String,
    resolve: {
      type: Function,
      default: () => false
    },
  },
  methods: {
    dialogConfirm() {
      this.$emit('confirm');
      this.$emit('close');
      this.resolve(true);
    },
    dialogDismiss() {
      this.$emit('dismiss');
      this.$emit('close');
      this.resolve(false);
    },
  }
};
</script>

<style lang="scss" scoped>
@import "@style/src/component/modal-confirm-styles.scss";

</style>
